<template>
  <div id="app">
    <!-- <input type="number" v-model.number="page"> /{{numPages}} -->
    <pdf
      src="https://www.gjtool.cn/pdfh5/git.pdf"
      @progress="handleLoading"
      :page="page"
    ></pdf>
  </div>
</template>

<script>
// import { Dialog } from "vant";
// import pdf from 'vue-pdf'
import pdf from '@/components/pdf'
export default {
  name: "App",
  components: {
    pdf
  },
  data() {
    return {
      page: 1,
      numPages: 0,
    };
  },
  mounted() {
    
  },
  methods: {
    handleLoading(e) {
      console.log(e)
    }
  }
};
</script>

<style lang="scss">

</style>
