<template>
  <div>
    <div v-if="loading && src"  class="loading-wrapper">
      <Loading color="#fff">PDF加载中...</Loading>
    </div>
    <pdf
      v-if="src"
      :src="src"
      :page="currentPage"
      @num-pages="pageCount = $event"
      @page-loaded="currentPage = $event"
      @error="handleLoadError"
      @loaded="loading = false"
    ></pdf>
    <div v-show="!loading" class="dp-pdf-fixed-btns">
      <div class="pager">
        {{ currentPage }} / {{ pageCount }}
      </div>
      <div class="right-btns">
        <Button :disabled="currentPage - 1 <1 " @click="handleChangePage(-1)">
          <Icon name="arrow-left" />
        </Button>
        <Button :disabled="currentPage + 1 > pageCount " @click="handleChangePage(+1)">
          <Icon name="arrow" />
        </Button>
      </div>
    </div>
  </div>
</template>
 
<script>
import pdf from "vue-pdf";
import { Button, Icon, Loading, Dialog } from 'vant';

export default {
  components: {
    pdf,
    Button,
    Icon,
    Loading
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      pageCount: 0,
      src: '',
      title: ''
    };
  },
  mounted() {
    // if (self != top) { 
    //   if (!this.domains.includes(parent.document.domain)) {
    //     Dialog({
    //       title: '钉耙获客',
    //       message: '非安全环境中运行！ ',
    //       confirmButtonColor: '#12d2cb',
    //       confirmButtonText: '确定'
    //     })
    //     return
    //   }
    // }
    const { file, name } = this.parseQueryString(window.location.search);
    if (!file) {
      Dialog({
        title: '钉耙获客',
        message: '未传入PDF文件地址！ ',
        confirmButtonColor: '#12d2cb',
        confirmButtonText: '确定'
      })
      return
    }
    if (name) {
      this.title = decodeURIComponent(name)
      document.title = this.title
    }
    this.src = decodeURIComponent(file)
  },
  methods: {
    parseQueryString(queryString, decode) {
      var config = {};
      var index = queryString.indexOf("?");
      if (index > -1) {
        queryString
          .substr(index + 1)
          .split("&")
          .forEach(function (str) {
            if (!str.length) {
              return;
            }
            var query = str.split("=");
            var key = query.shift();
            var isArr = key.slice(-2) === "[]";
            if (isArr) {
              key = key.slice(0, -2);
              if (!config[key]) {
                config[key] = [];
              }
            }
            var value = query.shift();
            if (!value || !value.length) {
              return;
            } else if (value === "true") {
              value = true;
            } else if (value === "false") {
              value = false;
            } else if (!isNaN(value)) {
              value = Number(value);
            } else if (typeof value === "string") {
              if (decode) {
                value = decodeURIComponent(value);
              }
            }
            if (isArr) {
              config[key].push(value);
            } else {
              config[key] = value;
            }
          });
      }
      return config;
    },
    handleChangePage(dir) {
      let tmp = this.currentPage + dir
      if (tmp > this.pageCount) {
        this.currentPage = this.pageCount
      } else if (tmp <= 0) {
        this.currentPage = 1
      } else {
        this.currentPage = tmp
      }
    },
    handleLoadError(e) {
      console.log(e)
      this.loading = false
      Dialog({
        title: '钉耙获客',
        message: 'PDF 文件载入失败。',
        confirmButtonColor: '#12d2cb',
        confirmButtonText: '确定'
      })
    }
  },
};
</script>
<style lang="scss" scoped>
  .loading-wrapper {
    position: fixed;
    z-index: 3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0, .4);
  }
  .dp-pdf-fixed-btns {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding-bottom: 40px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    background: #eee;
    .pager {
      padding-left: 10px;
    }
    .right-btns {
      width: 120px;
      display: flex;
      justify-content: space-around;
    }
  }
</style>